import React from "react"

import Tile from "@kiwicom/orbit-components/lib/Tile"
import TileGroup from "@kiwicom/orbit-components/lib/TileGroup"

import Container from "components/container"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Lost Property">
    <Hero title="Lost Property Information" />
    <Container size="medium">
      <TileGroup>
        <Tile
          title="Lost Property Form"
          description="Use this form to report items of lost property"
          href="https://notionforms.io/forms/kjbnkfdjsefuivnbftuohg8e4wjfiosvdjbeu38w9fo"
          external
        />
        <Tile
          title="Lost Property Log"
          description="A list of the known lost property and where it is currently located"
          href="https://goember.notion.site/Lost-Property-8c53baa7c4854f04b5340ffdb6679242"
          external
        />
      </TileGroup>
    </Container>
  </DriveLayout>
)

export default Page
